import { ReactNode } from 'react';

interface Props {
	div?: boolean;
	text?: string;
	children?: ReactNode;
	styles?: any;
	line?: number;
	margin?: string;
	padding?: string;
	wordBreak?: string;
	className?: any;
}
// NOTE: do not use p inside p OR div inside div
const TextClamp = ({
	div,
	text,
	children,
	styles,
	line = 2,
	margin = '0',
	padding = '0',
	className,
	wordBreak = 'normal'
}: Props) => {
	const containerStyle: any = {
		display: '-webkit-box',
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
		WebkitLineClamp: line,
		margin: margin,
		padding: padding,
		wordBreak: 'normal',
		...styles
	};

	return div ? (
		<div className={className} style={containerStyle}>
			{children ? children : text}
		</div>
	) : (
		<p className={className} style={containerStyle}>
			{children ? children : text}
		</p>
	);
};

export default TextClamp;
