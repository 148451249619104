'use client';

import TextClamp from '@/components/atoms/text-clamp';
import styles from './search.module.scss';

interface SlidingTextProps {
	minHeight?: number;
	fontSize?: number;
	marginTop?: number;
	stopAnimation?: boolean;
}

const SlidingText: React.FC<SlidingTextProps> = ({ minHeight = 15, fontSize = 16, marginTop = 13, stopAnimation }) => {
	const textData = [
		`Search for "medicine products"`,
		`Search for "healthcare products"`,
		`Search for "beauty products"`,
		`Search for "medicine products"`,
		`Search for "healthcare products"`,
		`Search for "beauty products"`,
		`Search for "medicine products"`,
		`Search for "healthcare products"`,
		`Search for "beauty products"`,
		`Search for "healthcare products"`
	];
	const itemDuration = 1500;
	const totalDuration = textData.length * itemDuration;

	return (
		<div style={{ minHeight }} className={styles.slider_text_wrapper}>
			{stopAnimation ? (
				<div style={{ marginTop: marginTop, fontSize: fontSize, color: '#a0aec0' }}>Search for products</div>
			) : (
				textData.map((d, i) => (
					<TextClamp
						key={i}
						className={styles.common}
						div
						styles={{
							animationDelay: `${i * itemDuration}ms`,
							animationDuration: `${totalDuration}ms`,
							fontSize: fontSize,
							marginTop: marginTop
						}}
						text={d}
						line={1}
					/>
				))
			)}
		</div>
	);
};

export default SlidingText;
